import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack';

/**
 * useFetchData:
 * This function is used to handle fetching data from the server in SSR mode
 * It takes the request and the options for the fetch
 * It returns the data and the error
 *
 * @param {NitroFetchRequest} request: The request to fetch the data
 * @param {NitroFetchOptions} opts: The options for the fetch
 * @returns {data, error}
 *
 * @example
 * const {data}: {data: Ref<Product>} = await useFetchData<Product>({
 *  url: 'https://api.example.com/data',
 *  transform: (data) => data as Product
 * });
 */
export async function useFetchData<T, Error = unknown>(
  request: NitroFetchRequest,
  opts?: NitroFetchOptions<NitroFetchRequest, 'get'> | undefined
) {
  return useFetch<T, Error>(request, {
    ...opts,
    $fetch: useNuxtApp().$api,
  });
}
